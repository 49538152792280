.layout-complex-wrapper::after,
.nested::after,
.wrapper::after,
aside::after {
  clear: both;
  content: '';
  display: table;
}
.highcharts-credits {
  display: none;
}
.map-container > iframe,
.video-container > iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden] {
  display: none;
}
a:focus {
  outline: thin dotted;
}
a:active,
a:hover {
  outline: 0;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: 700;
}
dfn {
  font-style: italic;
}
hr {
  height: 0;
}
mark {
  background: #ff0;
  color: #000;
}
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}
pre {
  white-space: pre-wrap;
}
q {
  quotes: '\201C''\201D''\2018''\2019';
}
small {
  font-size: 80%;
}
img {
  border: 0;
}
figure {
  margin: 0;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}
button,
input {
  line-height: 1.335;
}
button,
select {
  text-transform: none;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
input[type='checkbox'],
input[type='radio'] {
  padding: 0;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
@media print {
  *,
  ::after,
  ::before {
    background: 0 0 !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important;
  }
}
html {
  font: 75%/1.335 TradeGothic;
}
body {
  background: #fff;
  margin: 0;
  overflow: hidden;
  padding: 0;
  min-width: 0;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
}
body,
html {
  height: 100%;
  overflow: hidden;
}
* {
  outline: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0 0 0.25em 0;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}
h1 {
  font-size: 24px;
  font-size: 2rem;
  margin-top: 1.16667em;
}
h1 small {
  display: block;
}
h2 {
  font-size: 20px;
  font-size: 1.66667rem;
  margin-top: 1.4em;
}
h3 {
  font-size: 17px;
  font-size: 1.41667rem;
  margin-top: 1.64706em;
}
h4 {
  font-size: 15px;
  font-size: 1.25rem;
  margin-top: 1.86667em;
}
h5 {
  font-size: 12px;
  font-size: 1rem;
  margin-top: 2.33333em;
}
h6 {
  font-size: 11px;
  font-size: 0.91667rem;
  margin-top: 2.54545em;
}
@media print {
  h2,
  h3 {
    page-break-after: avoid;
  }
}
p {
  margin: 0 0 1em;
}
p:first-child {
  margin-top: 0;
}
a {
  -webkit-transition: color 0.3s linear;
  transition: color 0.3s linear;
}
a,
a:visited {
  color: #f78e1e;
  text-decoration: none;
}
@media print {
  a {
    text-decoration: underline;
  }
  a[href]::after {
    content: ' (' attr(href) ')';
  }
  a[href^='#']::after,
  a[href^='javascript:']::after {
    content: ' ';
  }
  a:visited {
    text-decoration: underline;
  }
}
a:active,
a:focus,
a:hover {
  color: #999;
}
@media print {
  h2,
  h3,
  h4,
  p {
    orphans: 3;
    widows: 3;
  }
}
@media print {
  abbr[title]::after {
    content: ' (' attr(title) ')';
  }
}
abbr,
acronym {
  border-bottom: 1px dotted #999;
  cursor: help;
}
::-moz-selection {
  background: #000;
  color: #fff;
}
::selection {
  background: #000;
  color: #fff;
}
ul {
  list-style-image: none;
  list-style: none;
  padding-left: 2em;
}
ol,
ul {
  display: block;
  margin: 0 0 1em 3em;
  padding: 0 0 0 1.66667em;
}
ol ul,
ul ul {
  margin-left: 1.5em;
  margin-top: 0;
}
dd {
  margin: 0;
}
form {
  margin: 0;
}
button,
input,
label,
select,
textarea {
  display: block;
}
#wrapper input[type='hidden'] {
  display: none;
}
fieldset {
  border: none;
  margin: 1em 0 0;
  padding: 0;
}
fieldset.flush,
fieldset:first-child {
  margin-top: 0;
}
fieldset legend {
  display: none;
}
fieldset ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
fieldset label,
fieldset label + select {
  display: block;
}
fieldset label.inline {
  display: inline-block;
  padding-right: 5px;
}
fieldset label input {
  display: inline;
}
fieldset input[type='text'],
fieldset input[type='number'],
fieldset input[type='password'],
fieldset input[type='email'],
fieldset input[type='tel'] {
  height: 42px;
}
fieldset input[type='text'],
fieldset input[type='number'],
fieldset input[type='password'],
fieldset input[type='email'],
fieldset input[type='tel'],
fieldset textarea {
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 0;
  max-width: 100%;
  padding: 0.66667em;
  width: 100%;
}
fieldset input[type='text']::-webkit-input-placeholder,
fieldset input[type='number']::-webkit-input-placeholder,
fieldset input[type='password']::-webkit-input-placeholder,
fieldset input[type='email']::-webkit-input-placeholder,
fieldset input[type='tel']::-webkit-input-placeholder,
fieldset textarea::-webkit-input-placeholder {
  color: #666;
}
fieldset input[type='text']:-moz-placeholder,
fieldset input[type='number']:-moz-placeholder,
fieldset input[type='password']:-moz-placeholder,
fieldset input[type='email']:-moz-placeholder,
fieldset input[type='tel']:-moz-placeholder,
fieldset textarea:-moz-placeholder {
  color: #666;
}
fieldset input[type='text']::-moz-placeholder,
fieldset input[type='number']::-moz-placeholder,
fieldset input[type='password']::-moz-placeholder,
fieldset input[type='email']::-moz-placeholder,
fieldset input[type='tel']::-moz-placeholder,
fieldset textarea::-moz-placeholder {
  color: #666;
}
fieldset input[type='text']:-ms-input-placeholder,
fieldset input[type='number']:-ms-input-placeholder,
fieldset input[type='password']:-ms-input-placeholder,
fieldset input[type='email']:-ms-input-placeholder,
fieldset input[type='tel']:-ms-input-placeholder,
fieldset textarea:-ms-input-placeholder {
  color: #666;
}
fieldset textarea {
  resize: vertical;
}
fieldset input[type='checkbox'],
fieldset input[type='radio'] {
  float: left;
  height: 1px;
  margin: 0;
  opacity: 0;
  padding: 0;
  width: 1px;
}
fieldset input[type='checkbox'] + label,
fieldset input[type='radio'] + label {
  clear: none;
  display: block;
  padding: 0.5em 0.41667em 0.33333em 2.25em;
}
fieldset input[type='checkbox'] + label::before,
fieldset input[type='radio'] + label::before {
  display: inline-block;
  margin-left: -2.16667em;
  margin-right: 0.66667em;
  vertical-align: middle;
}
fieldset input[type='checkbox'] + label.inline,
fieldset input[type='radio'] + label.inline {
  display: inline-block;
}

fieldset input[type='radio']:focus + label {
  color: #4d4d4d;
}

fieldset input[type='checkbox']:focus + label {
  color: #4d4d4d;
}

fieldset + button {
  margin-top: 1em;
}
fieldset .checkbox-list > li,
fieldset .radio-list > li {
  margin: 0 0 0 1em;
}
.decorator-select,
.decorator-select-multiple {
  background-color: #eee;
  background-image: -webkit-linear-gradient(top, #eee, #ccc);
  background-image: linear-gradient(to bottom, #eee, #ccc);
  border: 1px solid #ccc;
  border-radius: 0;
  display: inline-block;
  width: 100%;
}
.decorator-select select,
.decorator-select-multiple select {
  -webkit-appearance: none;
  background: 0 0;
  border: none;
  color: #000;
  display: block;
  outline: 0;
  padding: 6px 10px 6px 10px;
  position: relative;
  width: 100%;
}
.decorator-select {
  position: relative;
}

.decorator-select select {
  height: 40px;
}
.decorator-select-multiple select {
  min-height: 40px;
}
.no-js .custom-file-upload {
  display: none;
}
.js input[type='file'] {
  visibility: hidden;
  width: 0;
}
table {
  border-collapse: collapse;
  font-size: 12px;
  font-size: 1rem;
  width: 100%;
}
@media only screen and (min-width: 48em) {
  table {
    font-size: 14px;
    font-size: 1.16667rem;
  }
}
@media print {
  table thead {
    display: table-header-group;
  }
}
table th {
  background-color: #4d4d4d;
  color: #fff;
  font-weight: 400;
  vertical-align: middle;
}
table td {
  border: 1px solid #ccc;
  padding: 0.5em;
}
table td,
table th {
  border: 1px solid #000;
  padding: 0.66667em;
  text-align: left;
}
table tr:nth-child(2n) > td {
  background-color: #eee;
}
table tr:nth-child(2n) > td.empty,
table tr:nth-child(2n) > td.plain {
  background-color: transparent;
}
embed,
img,
object {
  height: auto;
  max-width: 100%;
}
@media print {
  embed,
  img,
  object {
    max-width: 100% !important;
  }
}
img {
  -ms-interpolation-mode: bicubic;
}
[class*=' icon-'],
[class^='icon-'] {
  font-family: icomoon;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  speak: none;
  text-transform: none;
}
header {
  background: #eee;
}
header .column,
header .columns {
  margin-top: 0;
}
#header {
  height: 115px;
  margin-bottom: 2em;
}
#brand img {
  border: none;
}
.branding-logo {
  display: block;
  float: left;
  height: 47px;
  width: 200px;
}
.wrapper {
  margin-left: 0;
  margin-right: 0;
  min-width: 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1220px;
  padding: 0;
}
.column {
  float: left;
  width: 45%;
  margin-right: 2%;
}
#app-map {
  height: 100%;
  overflow: visible;
  padding-top: 36px;
  z-index: 5;
  margin-top: -84px;
}
.map-ribbon {
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  height: 47px;
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 13;
}

.map-ribbon.top a:not(:first-child) {
  color: #000;
  display: inline-block;
  float: right;
  font-size: 1.083em;
  font-weight: 400;
  padding: 1.25em 1.66667em 1.25em;
  right: 0;
  text-decoration: none;
  text-transform: uppercase;
  top: 0;
  z-index: 100;
}
.map-ribbon.bottom {
  bottom: 0;
}
.map-ribbon.bottom .inner {
  overflow: hidden;
}
#wrapper {
  border-right: 1px solid #ccc;
}
#secondary,
#tertiary {
  background-color: rgba(180, 180, 180, 0.4);
}
#wrapper {
  margin: auto;
  position: relative;
  width: 960px;
}
#content {
  float: left;
  width: 960px;
  border: none;
}
#primary {
  display: inline;
  float: left;
  margin-left: 240px;
  width: 520px;
}
#secondary {
  display: inline;
  float: left;
  margin-left: -760px;
  width: 220px;
}
#tertiary {
  float: left;
  margin-left: 20px;
  width: 160px;
}
.layout-centered,
.layout-primary {
  border: 1px dashed #4d4d4d;
}
.layout-secondary,
.layout-tertiary {
  background-color: #333;
}
.layout-primary,
.layout-secondary,
.layout-tertiary {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  padding: 16px;
  margin-top: 0;
  width: 100%;
}
.layout-centered {
  padding: 16px;
  margin-top: 0;
  width: 100%;
}
@media only screen and (min-width: 48em) {
  .layout-centered {
    clear: both;
    margin-left: 16.66667%;
    margin-top: 0;
    padding: 16px;
    width: 66.66667%;
  }
  .layout-centered:first-child {
    margin-top: 0;
  }
}
@media only screen and (min-width: 48em) {
  .template-two-column.not-shifted.no-gutters .layout-primary {
    padding: 16px;
    margin-top: 0;
    width: 75%;
  }
}
@media only screen and (min-width: 48em) {
  .template-two-column.not-shifted.no-gutters .layout-secondary {
    padding: 16px;
    margin-top: 0;
    width: 25%;
  }
}
@media only screen and (min-width: 48em) {
  .template-two-column.not-shifted.gutters .layout-primary {
    padding: 16px;
    margin-top: 0;
    width: 74.67213%;
    margin-left: 1.31148%;
  }
  .template-two-column.not-shifted.gutters
    .layout-primary:first-child {
    margin-left: 0;
  }
}
@media only screen and (min-width: 48em) {
  .template-two-column.not-shifted.gutters .layout-secondary {
    padding: 16px;
    margin-top: 0;
    width: 24.01639%;
    margin-left: 1.31148%;
  }
  .template-two-column.not-shifted.gutters
    .layout-secondary:first-child {
    margin-left: 0;
  }
}
@media only screen and (min-width: 48em) {
  .template-two-column.shifted.no-gutters .layout-primary {
    padding: 16px;
    margin-top: 0;
    width: 75%;
    margin-left: 25%;
    margin-right: -100%;
  }
}
@media only screen and (min-width: 48em) {
  .template-two-column.shifted.no-gutters .layout-secondary {
    padding: 16px;
    margin-top: 0;
    width: 25%;
    margin-left: 0;
    margin-right: -100%;
  }
}
@media only screen and (min-width: 48em) {
  .template-two-column.shifted.gutters .layout-primary {
    padding: 16px;
    margin-top: 0;
    width: 74.67213%;
    margin-left: 25.32787%;
    margin-right: -100%;
  }
}
@media only screen and (min-width: 48em) {
  .template-two-column.shifted.gutters .layout-secondary {
    padding: 16px;
    margin-top: 0;
    width: 24.01639%;
    margin-left: 0;
    margin-right: -100%;
  }
}
@media only screen and (min-width: 48em) {
  .template-three-column.not-shifted.no-gutters .layout-primary {
    padding: 16px;
    margin-top: 0;
    width: 50%;
  }
}
@media only screen and (min-width: 48em) {
  .template-three-column.not-shifted.no-gutters .layout-secondary {
    padding: 16px;
    margin-top: 0;
    width: 25%;
  }
}
@media only screen and (min-width: 48em) {
  .template-three-column.not-shifted.no-gutters .layout-tertiary {
    padding: 16px;
    margin-top: 0;
    width: 25%;
  }
}
@media only screen and (min-width: 48em) {
  .template-three-column.not-shifted.gutters .layout-primary {
    padding: 16px;
    margin-top: 0;
    width: 49.34426%;
    margin-left: 1.31148%;
  }
  .template-three-column.not-shifted.gutters
    .layout-primary:first-child {
    margin-left: 0;
  }
}
@media only screen and (min-width: 48em) {
  .template-three-column.not-shifted.gutters .layout-secondary {
    padding: 16px;
    margin-top: 0;
    width: 24.01639%;
    margin-left: 1.31148%;
  }
  .template-three-column.not-shifted.gutters
    .layout-secondary:first-child {
    margin-left: 0;
  }
}
@media only screen and (min-width: 48em) {
  .template-three-column.not-shifted.gutters .layout-tertiary {
    padding: 16px;
    margin-top: 0;
    width: 24.01639%;
    margin-left: 1.31148%;
  }
  .template-three-column.not-shifted.gutters
    .layout-tertiary:first-child {
    margin-left: 0;
  }
}
@media only screen and (min-width: 48em) {
  .template-three-column.shifted.no-gutters .layout-primary {
    padding: 16px;
    margin-top: 0;
    width: 50%;
    margin-left: 25%;
    margin-right: -100%;
  }
}
@media only screen and (min-width: 48em) {
  .template-three-column.shifted.no-gutters .layout-secondary {
    padding: 16px;
    margin-top: 0;
    width: 25%;
    margin-left: 0;
    margin-right: -100%;
  }
}
@media only screen and (min-width: 48em) {
  .template-three-column.shifted.no-gutters .layout-tertiary {
    padding: 16px;
    margin-top: 0;
    width: 25%;
    margin-left: 75%;
    margin-right: -100%;
  }
}
@media only screen and (min-width: 48em) {
  .template-three-column.shifted.gutters .layout-primary {
    padding: 16px;
    margin-top: 0;
    width: 49.34426%;
    margin-left: 25.32787%;
    margin-right: -100%;
  }
}
@media only screen and (min-width: 48em) {
  .template-three-column.shifted.gutters .layout-secondary {
    padding: 16px;
    margin-top: 0;
    width: 24.01639%;
    margin-left: 0;
    margin-right: -100%;
  }
}
@media only screen and (min-width: 48em) {
  .template-three-column.shifted.gutters .layout-tertiary {
    padding: 16px;
    margin-top: 0;
    width: 24.01639%;
    margin-left: 75.98361%;
    margin-right: -100%;
  }
}
.layout-complex-wrapper {
  margin-left: 0;
  margin-right: 0;
  min-width: 0;
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  padding: 0;
}
.layout-complex-primary {
  padding: 16px;
  margin-top: 0;
  width: 100%;
  border: 1px dashed #4d4d4d;
}
@media only screen and (min-width: 48em) {
  .layout-complex-primary {
    padding: 16px;
    margin-top: 0;
    width: 58.33333%;
  }
}
@media only screen and (min-width: 60em) {
  .layout-complex-primary {
    padding: 16px;
    margin-top: 0;
    width: 50%;
  }
}
.layout-complex-nav {
  padding: 16px;
  margin-top: 0;
  width: 100%;
  background-color: #333;
}
@media only screen and (min-width: 76.25em) {
  .layout-complex-nav {
    padding: 16px;
    margin-top: 0;
    width: 12.5%;
  }
}
.layout-complex-tertiary {
  padding: 16px;
  margin-top: 0;
  width: 100%;
  background-color: #333;
}
@media only screen and (min-width: 48em) {
  .layout-complex-tertiary {
    padding: 16px;
    margin-top: 0;
    width: 41.66667%;
  }
}
@media only screen and (min-width: 60em) {
  .layout-complex-tertiary {
    padding: 16px;
    margin-top: 0;
    width: 25%;
  }
}
.layout-complex-footer {
  padding: 16px;
  margin-top: 0;
  width: 100%;
}
@media only screen and (min-width: 60em) {
  .layout-complex-footer {
    padding: 16px;
    margin-top: 0;
    width: 25%;
  }
}
@media only screen and (min-width: 76.25em) {
  .layout-complex-footer {
    padding: 16px;
    margin-top: 0;
    width: 12.5%;
  }
}
.nested,
aside {
  margin-left: 0;
  margin-right: 0;
  min-width: 0;
  width: 100%;
  margin-top: 16px;
  max-width: none;
  padding: 0;
}
.nested:first-child,
aside:first-child {
  margin-top: 0;
}
.intro {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 5px;
  box-shadow: 0 2px 10px #999;
  color: #999;
  font-size: 14px;
  font-size: 1.16667rem;
  height: 100%;
  left: 130px;
  max-height: 256px;
  max-width: 390px;
  padding: 15px;
  position: absolute;
  top: 45px;
  width: 100%;
  z-index: 10;
}
.intro h1 {
  color: #666;
  font-family: Futura;
  text-transform: uppercase;
  margin: 0 0 1.08333em 0;
}
.intro .close-intro {
  background: #fab368;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  height: 25px;
  width: 25px;
  display: block;
  position: absolute;
  text-align: center;
  vertical-align: middle;
  top: 4px;
  right: 4px;
}
.intro .close-intro:hover {
  background: #f78e1e;
}
.intro .close-intro:before {
  content: 'x';
  font-family: Futura;
  line-height: 27px;
  text-transform: uppercase;
}
footer {
  background: #eee;
}
footer .column,
footer .columns {
  margin-top: 0;
}
#footer {
  clear: both;
}
.btn,
.btn-secondary,
button {
  border-radius: 0;
  border-style: none;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  font-size: 1.5rem;
  margin-right: 0.27778em;
  padding: 0.33333em 0.66667em;
  text-align: center;
  text-decoration: none;
  -webkit-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
}
@media only screen and (min-width: 48em) {
  .btn + .btn,
  .btn + button,
  .btn-secondary + .btn,
  .btn-secondary + button,
  button + .btn,
  button + button {
    margin-top: 0;
  }
}
.btn-full-width.btn,
.btn-full-width.btn-secondary,
button.btn-full-width {
  display: block;
  margin-right: 0;
  margin-top: 1.33333em;
}
.btn-full-width.btn-secondary:first-child,
.btn-full-width.btn:first-child,
button.btn-full-width:first-child {
  margin-top: 0;
}
.btn-small.btn,
.btn-small.btn-secondary,
button.btn-small {
  font-size: 14px;
  font-size: 1.16667rem;
  padding: 0.42857em 0.85714em;
}
.btn + .btn,
.btn + button,
.btn-secondary + .btn,
.btn-secondary + button,
button + .btn,
button + button {
  margin-top: 0.41667em;
}
button {
  cursor: pointer;
}
.btn,
button {
  background-color: #4d4d4d;
  border: 1px solid #343434;
}
@media print {
  .btn,
  button {
    display: none;
  }
}
.btn:active,
.btn:focus,
.btn:hover,
button:active,
button:focus,
button:hover {
  background-color: #676767;
  color: #fff;
}
.btn-secondary {
  background-color: #333;
  border: 1px solid #1a1a1a;
  color: #000;
}
@media print {
  .btn-secondary {
    display: none;
  }
}
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover {
  background-color: #4d4d4d;
  color: #000;
}
.cake-sql-log {
  display: none;
}
.close-selection,
.scope-selection .close-selection {
  background: 0 0;
  float: right;
  width: auto;
  padding: 15px 15px 0 0;
  margin-bottom: -30px;
}
.zoom-controls {
  height: 41px;
  left: 35px;
  position: absolute;
  top: 80px;
  width: 81px;
  z-index: 10;
}
.zoom-controls a {
  background-repeat: no-repeat;
  display: block;
  float: left;
  height: 41px;
  text-indent: -9999em;
  outline: none;
}
.zoom-controls a.out {
  background-position: top left;
  width: 40px;
}
.zoom-controls a.out:active {
  background-position: 0 -82px;
}
.zoom-controls a.out.disabled {
  background-position: 0 -164px;
}
.zoom-controls a.in {
  background-position: top right;
  width: 41px;
}
.zoom-controls a.in:active {
  background-position: -40px -41px;
}
.zoom-controls a.in.disabled {
  background-position: -40px -123px;
}
.overview-return {
  display: block;
  float: left;
  height: 28px;
  left: 0;
  margin: 10px 0 0 25px;
  position: absolute;
  top: 0;
  width: 251px;
  z-index: 60;
}
.overview-return > a {
  display: block;
  text-indent: -99999em;
  position: absolute;
}
a.historical,
a.overview {
  background-repeat: no-repeat;
  height: 28px;
  width: 300px;
}
a.overview {
  background-position: top left;
  left: 0;
  width: 150px;
}
a.historical {
  background-position: top right;
  right: 0;
  width: 100px;
}
a.overview:hover {
  background-position: 0 -58px;
}
a.historical:hover {
  background-position: -143px -29px;
  width: 108px;
}
.clear-all-filters {
  clear: both;
  color: #54cafe;
  display: inline-block;
  margin: 0.83333em 0 0;
  text-decoration: none;
}
.clear-all-filters.disabled {
  display: none;
}
#export-buttons {
  float: left;
  padding: 0 10px 10px 10px;
}
a.pdf {
  background-position: 0 0;
}
a.xls {
  background-position: -69px 0;
}
a.json {
  background-position: -139px 0;
}
a.json,
a.pdf,
a.xls {
  display: block;
  height: 31px;
  float: left;
  text-indent: -9999em;
  margin-left: 0;
  margin-right: 0;
  width: 70px;
}
.close-export {
  border: 1px #ddd solid;
  box-shadow: 0 0 5px #ddd;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  float: right;
  height: 25px;
  margin: 5px;
  padding: 5px;
  position: absolute;
  top: 10px;
  right: 25px;
  width: 140px;
  z-index: 2000;
}
.close-legend {
  display: block;
  height: 17px;
  float: right;
  position: relative;
  right: 55px;
  text-indent: -99999em;
  top: 10px;
  width: 55px;
}
.close {
  color: #54cafe;
  display: block;
  float: right;
  font-size: 1.167em;
  margin: 5px 5px 0 0;
  text-decoration: none;
  text-transform: uppercase;
}
.list-plain,
.meta {
  padding-left: 0;
}
.list-plain li::before,
.meta li::before {
  display: none;
}
.compliance {
  font-size: 12px;
  font-size: 1rem;
  display: block;
  float: left;
  padding: 0 0 1.25em 0 !important;
  width: 100%;
}
.country-scope .compliance {
  border-bottom: 1px #666 solid;
  margin: 0 0 0.83333em;
}
.factory-scope .compliance {
  margin: 0 0 0 0.83333em !important;
  padding: 0 !important;
}
#region-form .compliance ul {
  display: inline-block;
  margin: 0 0.41667em 0 0.41667em;
}
.compliance li {
  text-transform: uppercase;
  margin-top: 0.41667em;
  color: #fff;
}
.compliance li:first-child {
  margin-top: 0;
}
.country-scope .conduct {
  border-bottom: 1px #666 solid;
}
.country-scope .conduct h3 {
  width: 100%;
}
#region-form .factory-scope .conduct {
  display: inline-block;
  padding: 0 0.5em;
}
#region-form .factory-scope .conduct ul {
  margin-bottom: 0;
}
#region-form .conduct ul {
  color: #fff;
  display: inline-block;
  list-style: disc;
  text-transform: uppercase;
  margin: 0 0.41667em 1.25em 1.25em;
}
.country-scope .ratings {
  border-bottom: 1px #666 solid;
  padding: 1.25em 0;
}
#region-form .ratings ul {
  color: #fff;
  display: inline-block;
  text-transform: uppercase;
  margin: 0 0.41667em 0 0.41667em;
}
#region-form .ratings ul span {
  color: #999;
}
.accessibility {
  height: 0;
  left: -9999em;
  top: -9999em;
  line-height: 0;
  position: absolute;
  text-indent: -9999em;
}
.browser-alert {
  background-color: #4d4d4d;
  color: #fff;
  font-size: 24px;
  font-size: 2rem;
  padding: 1.33333em;
  text-align: center;
}
.browser-alert a {
  color: #eee;
}
.browser-support {
  background-color: #fffff1;
  background-image: -webkit-linear-gradient(bottom, #fffff1, #ffffcd);
  background-image: linear-gradient(to top, #fffff1, #ffffcd);
  border-bottom: 2px #333 solid;
  border-top: 2px #333 solid;
  color: #404040;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;
}
.browser-support .inner {
  float: left;
  margin: 8px 0 15px 2%;
  padding: 0;
  width: 60%;
}
.browser-support .inner.icons {
  padding: 0 50px 0 0;
}
.browser-support .inner + .inner {
  float: right;
  width: auto;
}
.browser-support h2 {
  font-weight: 700;
}
.browser-support p {
  margin: 0;
}
.browser-support .icon {
  background-repeat: no-repeat;
  display: inline-block;
  height: 45px;
  width: 42px;
}
.browser-support .chrome {
  background-position: 0 0;
}
.browser-support .firefox {
  background-position: -55px 0;
}
.browser-support .opera {
  background-position: -117px 0;
}
.browser-support .safari {
  background-position: -175px 0;
}
.browser-support .ie {
  background-position: -232px 0;
}
.close-browser-support {
  cursor: pointer;
  display: block;
  height: 16px;
  position: absolute;
  top: 10px;
  right: 20px;
  width: 16px;
  z-index: 600;
}
.img-align-center {
  display: block;
  margin: 0 auto;
}
.img-full-width {
  display: block;
  width: 100%;
}
.map-container {
  position: relative;
}
.map-container::before {
  content: '';
  display: block;
  padding-top: 75%;
}
.map-container img {
  max-width: none;
}
.video-container {
  position: relative;
}
.video-container::before {
  content: '';
  display: block;
  padding-top: 56.25%;
}
.historical-slider,
.ui-slider-horizontal {
  background: 0 0 !important;
  border: none !important;
  cursor: pointer;
  height: 30px !important;
  margin: 372px auto;
  width: 300px;
}
.ui-slider-handle {
  border: none !important;
  height: 20px !important;
  margin-top: 10px;
  margin-left: -30px !important;
  outline: 0 !important;
  width: 60px !important;
}
.small {
  width: 25%;
}
.medium {
  width: 48%;
}
.large {
  width: 90%;
}
.error,
.red {
  color: red;
}
.hide {
  display: none;
}
.left {
  float: left;
  margin: 10px 5px 10px 0;
}
* html .left {
  margin-right: 0;
}
.right {
  float: right;
}
.clear {
  clear: both;
}
.clearleft {
  clear: left;
}
.clearright {
  clear: right;
}
.group:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.count {
  color: #fff;
  float: right;
  font-weight: 400;
  margin-right: 5px;
  font-size: 12px;
  font-size: 1rem;
}
label + .count {
  margin-right: 35px;
  position: absolute;
  right: 0;
  top: 0;
}
.notice {
  clear: both;
  color: #999;
  display: inline-block;
  font-size: 12px;
  font-size: 1rem;
  font-style: italic;
  margin: 15px 0 0;
  padding: 0 17px;
  text-align: left;
  width: 95%;
}
.scope-numbers + .notice {
  float: left;
  margin: 15px 0;
}
.filter-scope .notice {
  margin: 0;
}
.notice p {
  margin: 0;
}
.compliance-notice {
  border-bottom: 1px #666 solid;
  padding: 1.25em 0.83333em 0.83333em 0.83333em;
  text-align: left;
  text-transform: uppercase;
}
.compliance-notice p {
  margin-bottom: 0.41667em;
}
.world-scope .compliance-notice {
  border-top: 1px #666 solid;
  border-bottom: 0;
  margin-top: 1.25em;
}
.factory-scope .compliance-notice {
  border-bottom: none;
  border-top: 1px #666 solid;
}
.date-disclaimer {
  color: #666;
  display: block;
  font-size: 12px;
  font-size: 1rem;
  font-weight: 400;
  height: 47px;
  line-height: 47px;
  margin: auto;
  text-align: center;
  text-transform: uppercase;
  width: 200px;
}
div[dir='ltr'] {
  margin-left: 1px !important;
  margin-top: -1px !important;
  width: 256px !important;
  height: 256px !important;
}
iframe body {
  background: #000 !important;
}
.loading-mat {
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #fff;
  opacity: 0.8;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 11;
}
.scope-details.loading:before {
  left: 145px;
  top: 0;
}
.scope-selection .loading:before {
  top: -30px;
  left: 0;
}
.current-scope.loading,
.pane.loading {
  padding: 10px 0;
}
.scope-details.loading:before,
.scope-selection .loading:before {
  content: '';
  height: 24px;
  position: absolute;
  width: 24px;
}
.facets > li.current-scope {
  left: auto;
  position: relative;
}
.facets > li.current-scope fieldset {
  width: 320px;
}
.facets > li.current-scope .jspContainer fieldset {
  width: 310px;
}
.active-scope {
  left: auto;
  position: relative;
}
.scope-details {
  margin: 0;
  text-align: center;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.scope-details li {
  display: inline-block;
  font-size: 0.8em;
  margin: 0 0 0 20px;
}
.scope-details strong {
  color: #000;
  display: block;
  float: left;
  font-weight: 700;
  margin: 0 4px 0 0;
  text-transform: uppercase;
}
.scope-details .percents {
  display: block;
  clear: both;
  position: relative;
  text-align: left;
  z-index: 1;
}
.scope-selection li {
  border-bottom: 1px #484848 solid;
  border-top: none;
  position: relative;
  height: 47px;
  line-height: 47px;
  display: inline-block;
  float: left;
  margin-left: 0;
  width: 100%;
}
.scope-selection li:first-child {
  border-top: 1px #484848 solid;
}
.scope-selection li:after {
  content: '';
  color: #54cafe;
  height: 47px;
  line-height: 47px;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}
.scope-selection li.auto-height {
  background: 0 0;
  border: none;
  height: auto;
}
.scope-selection ol {
  overflow: hidden;
  position: relative;
}
.scope-selection ol h4 {
  color: #ccc;
  font-family: Futura;
  font-size: 2em;
  margin: 20px 0 10px;
}
.scope-selection ol h4:first-child {
  margin: 0 0 10px;
}
.scope-selection .jspPane {
  margin-left: 0;
}
.scope-selection label {
  display: inline-block;
  height: 45px;
  overflow: hidden;
  width: 225px;
}
.scope-selection .auto-height li {
  margin: 0;
  height: 47px;
  overflow: hidden;
}
#region-form-wrapper {
  overflow: hidden;
  padding: 30px 10px 10px;
  position: absolute;
  right: 5px;
  top: 30px;
  width: 355px;
  z-index: 11;
}
#region-form {
  background: #222;
  background: rgba(251, 251, 251, 0.95);
  box-shadow: 0 2px 10px #999;
  border-radius: 5px;
  color: #999;
  float: right;
  position: relative;
  width: 350px;
  z-index: 1000;
}
#region-form h2 {
  color: #666;
  display: inline-block;
  font-family: Futura;
  font-size: 18px;
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0 0 10px;
  padding: 0 10px;
  text-transform: uppercase;
  width: 300px;
}
#region-form ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
#region-form fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
#region-form ul {
  list-style: none;
  padding: 0;
  position: relative;
}
#region-form a.ui-slider-handle {
  background: 0 0;
  border: none;
  height: 20px;
  margin: 0;
  padding: 0;
  width: 20px;
}
#region-form span.filter-slider,
#region-form span.tmp-filter-slider {
  background-repeat: no-repeat;
  background-position: top left;
  background-color: transparent;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: right;
  height: 20px;
  margin-top: 12px;
  margin-right: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
}
#region-form span.filter-slider.active,
#region-form span.filter-slider.tmp-active,
#region-form span.tmp-filter-slider.active,
#region-form span.tmp-filter-slider.tmp-active {
  background-position: bottom left;
}
#region-form .jspContainer h2 {
  width: 290px;
}
#region-form > .inner {
  float: left;
}
#region-form .scope-details.loading {
  padding: 10px 0;
}
#region-form .nav li {
  border-bottom: 1px #999 solid;
  border-top: none;
  position: relative;
}
#region-form .nav li:first-child {
  border-top: 1px #999 solid;
}
#region-form .loading * {
  display: none;
}
#region-form fieldset.pane {
  clear: both;
}
#region-form fieldset.pane.loading {
  clear: both;
  display: block;
  padding: 10px 0;
}
.facets {
  width: 350px;
}
.facets > li {
  left: -9999em;
  padding: 0 15px 15px;
  position: absolute;
  width: 320px;
}
#region-form li a,
#region-form li label,
.step-back {
  font-size: 12px;
  font-size: 1rem;
  font-weight: 400;
}
ul#nav,
ul.meta,
ul.nav {
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}
ul.nav {
  list-style: none;
  margin: 0 0 1em 0;
  padding: 0;
}
#nav {
  height: 1.5em;
  overflow: hidden;
  position: absolute;
  top: 120px;
}
#nav a,
#nav li,
.meta li {
  display: block;
  float: left;
  padding: 0 1em;
  text-decoration: none;
  width: auto;
}
.nav a {
  display: block;
  height: 47px;
  line-height: 3.91667em;
  padding: 0 10px;
}
.nav li:after {
  content: '';
  color: #54cafe;
  height: 47px;
  line-height: 3.91667em;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}
.filter-scope ol.nav li {
  height: 47px;
  line-height: 3.91667em;
}
.nav a,
.scope-selection label,
.scope-selection li,
a.close-selection,
a.step-back {
  color: #f78e1e;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
}
.breadcrumbs {
  border-bottom: 4px #999 solid;
  margin: 0.41667em auto 0.83333em;
  overflow: hidden;
  padding: 0.83333em 0;
  width: 320px;
}
.breadcrumbs strong {
  color: #999;
  font-family: Georgia;
  font-size: 1.25em;
  font-style: italic;
  font-weight: 400;
  margin: 0 1.25em 0 0;
}
.breadcrumbs > a {
  cursor: pointer;
  display: block;
  display: inline-block;
  float: left;
  font-size: 12px;
  font-size: 1rem;
  font-weight: 400;
  height: 25px;
  line-height: 2.08333em;
  margin: 0 0.41667em 0 0;
  overflow: hidden;
  padding: 0 1.25em 0 0;
  text-decoration: none;
  text-transform: uppercase;
}
.breadcrumbs > a.disabled {
  color: #999;
  cursor: default;
}
.breadcrumbs > a.worldcrumb {
  display: inline-block;
  height: 24px;
  margin: 0;
  padding: 0;
  text-indent: -9999em;
  width: 35px;
}
.breadcrumbs > a.worldcrumb.disabled {
  cursor: default;
}
#region-form a.disabled.none {
  background: 0 0;
}
.bar-chart {
  background: #ddd;
  height: 4px;
  margin: 2px 0 2px 0;
  overflow: hidden;
  padding: 2px 2px 2px 2px;
  position: relative;
  text-align: left;
  width: 70px;
  z-index: 100;
}
.country-scope .bar-chart {
  width: 100px;
}
.bar-chart span {
  background: #fff;
  display: block;
  height: 100%;
  width: 0%;
}
.highcharts-container {
  position: relative;
  z-index: 2000;
  overflow: visible !important;
}
.historical-view {
  box-shadow: 0 0 8px #999;
  border: 1px #fff solid;
  height: 460px;
  margin: 0 0 25px;
  position: relative;
  width: 644px;
}
.historical-years {
  list-style: none;
  left: 115px;
  bottom: 50px;
  margin: 0 0 0 16px;
  padding: 0;
  position: absolute;
}
.historical-years li {
  color: #000;
  float: left;
  margin: 0 18px 0 0;
  text-shadow: 0 0.1em #494949;
}
.historical-years li.current {
  color: #ccc;
  text-shadow: 0 0.1em #1b1b1b;
}
.historical-view h1 {
  position: relative;
  z-index: 100;
  color: #ccc;
  margin: 16px;
}
.section.divisions,
.section.factories,
.section.workers {
  border-top: 3px #000 solid;
  display: inline-block;
}
.section.factories,
.section.workers {
  width: 150px;
}
.section.divisions {
  width: 315px;
}
.section.factories {
  margin: 0 0 0 10px;
}
.section.workers {
  float: right;
}
.section.divisions h2 {
  float: right;
  font-size: 12px;
  font-size: 1rem;
}
.section.divisions .highcharts-container {
  margin: -50px 0 0 0;
}
.worker-details {
  float: right;
  margin: 0;
  width: 120px;
}
.avg-age,
.worker-details {
  width: 130px;
}
.jspContainer .avg-age,
.jspContainer .worker-details {
  width: 120px;
}
.avg-age,
.female,
.migrant {
  font-size: 12px;
  font-size: 1rem;
  font-family: Futura;
  text-transform: uppercase;
}
.female,
.migrant {
  border-top: 1px #666 solid;
  float: left;
  padding: 10px 0 0 5px;
  width: 60px;
}
.country-scope .tier2BTL {
  text-align: left;
}
.country-scope .tier2BTL h2 {
  margin: 10px 10px 10px 10px;
}
.country-scope .notice {
  border-top: 1px #666 solid;
  padding: 5px 5px 5px 5px;
}
.jspContainer .female,
.jspContainer .migrant {
  width: 55px;
}
.avg-age {
  border-top: 1px #666 solid;
  clear: both;
  float: left;
  margin-top: 15px;
  padding-top: 5px;
  text-indent: 5px;
  text-transform: uppercase;
  width: 100%;
}
.worker-details strong {
  clear: both;
  color: #333;
  display: block;
  font-family: Futura;
  font-size: 24px;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: -0.05em;
  line-height: normal;
}
.worker-details sup {
  font-size: 0.5em;
  margin: 0 0 0 3px;
  vertical-align: top;
}
.manufacture-profile {
  margin: 0;
}
.manufacture-profile span.total {
  color: #000;
}
.manufacture-profile li {
  clear: both;
  font-size: 0.95em;
  margin: 5px 0 0;
  position: relative;
  text-transform: uppercase;
  text-indent: 95px;
  z-index: 10;
}
.manufacture-profile li > strong {
  /*color: #fff;*/
  color: red;
  clear: both;
  display: block;
  font-size: 12px;
  font-size: 1rem;
}
.manufacture-profile .key-color {
  border: 1px #666 solid;
  height: 12px;
  width: 12px;
  position: absolute;
  left: 72px;
  top: 0;
}
.manufacture-profile .key-color.apparel {
  background: #f78e1e;
}
.manufacture-profile .key-color.equipment {
  background: #fff;
}
.manufacture-profile .key-color.footwear {
  background: #999;
}
#country_piechart {
  height: 95px;
  left: -18px;
  overflow: hidden;
  position: absolute;
  text-indent: 0;
  top: 25px;
  z-index: 100;
}
.country-scope h3 {
  color: #999;
  float: left;
  font-size: 12px;
  font-size: 1rem;
  font-family: Futura;
  font-weight: 400;
  margin: 0.83333em 0 1.25em;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  width: 185px;
  z-index: 10;
}
.country-scope .section {
  border-bottom: 1px #666 solid;
  float: left;
  margin: 0;
  padding: 0 0 0.83333em;
  width: 100%;
}
.country-scope .profile {
  border-top: 1px #666 solid;
  float: left;
  width: 180px;
}
.country-scope .export-button {
  display: inline-block;
  margin: 20px auto;
}
.country-scope .factories,
.country-scope .workers {
  margin: 0 1.66667em 0.83333em 1.66667em;
  width: 110px;
  text-align: left;
}
.country-scope .factories {
  margin: 0 5.41667em 0.83333em 1.66667em;
}
.country-scope .workers {
  margin-left: 0;
  margin-right: 0;
}
#export {
  overflow: hidden;
}
.export-view {
  background: #333;
  bottom: 0;
  height: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1000;
}
.export-view .inner {
  height: 95.5%;
  margin: 0 1.66667em;
  position: relative;
}
#export-header {
  font-family: Futura;
  margin: 1.25em 1.25em 0 1.25em;
  text-transform: uppercase;
}
#export-header h2 {
  font-size: 2em;
  font-weight: 400;
  margin-bottom: 0;
  text-align: left;
}
#export-table {
  background: #eee;
  white-space: nowrap;
  overflow: hidden;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}
#export-table th {
  background: #ccc;
  border-left: 1px solid #aaa;
  border-right: 1px solid #aaa;
  color: #666;
  font-size: 12px;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  padding: 0.83333em 4.58333em 0.83333em 0.83333em;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
}
#export-table tr {
  font-size: 12px;
  font-size: 1rem;
}
#export-table tr:nth-child(2n + 2) {
  background: #fff !important;
}
#export-table td {
  text-align: left;
  white-space: nowrap;
  padding: 0.41667em 0 0.41667em 0.83333em;
  text-transform: uppercase;
}
#export-footer {
  color: #333;
  margin: 0 1.66667em 0 1.25em;
  overflow: hidden;
}
#export-footer p {
  color: #666;
  float: left;
  font-family: Georgia;
  font-size: 16px;
  font-size: 1.33333rem;
  font-style: italic;
}
#close-export {
  color: #54cafe;
  float: right;
  font-family: Arial;
  padding-left: 2.5em;
}
#date {
  float: right;
  font-family: Arial;
  margin: 1em 18.75em 0 0;
}
a.export-button {
  background-color: #ddd;
  background-image: -webkit-linear-gradient(top, #ddd, #ccc);
  background-image: linear-gradient(to bottom, #ddd, #ccc);
  border-radius: 2px;
  clear: both;
  color: #999;
  display: block;
  margin: 1.25em auto 0.41667em;
  padding: 1em 0;
  text-align: center;
  text-transform: uppercase;
  width: 276px;
  width: 100%;
}
.factory-label {
  color: #fff;
  float: right;
  font-size: 0.75em;
  margin: 0 1.66667em 0.41667em 0;
  text-transform: uppercase;
}
.factory-label + ol {
  clear: both;
}
.factory-scope h3 {
  color: #999;
  float: left;
  font-size: 12px;
  font-size: 1rem;
  font-weight: 400;
  margin: 0.83333em 0 1.25em;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  z-index: 10;
}
.factory-scope ul.profile,
.factory-scope ul.profile ul {
  margin: 0;
}
.factory-scope .profile > li {
  border-top: 1px #666 solid;
  clear: both;
  margin: 0;
  overflow: hidden;
  padding: 1.25em 0;
  width: 100%;
}
.rating-color {
  margin: 0 0.41667em 0 0;
  vertical-align: middle;
}
.numbers {
  margin: 0;
  text-transform: uppercase;
}
.numbers li {
  float: left;
  font-size: 10px;
  font-size: 0.83333rem;
  padding: 0;
  text-align: center;
  width: 80px;
}
.jspContainer .numbers li {
  width: 76px;
}
.numbers .count {
  display: block;
  color: #333;
  font-family: Futura;
  font-size: 22px;
  font-size: 1.83333rem;
  margin: 0;
  width: 100%;
}
.numbers sup {
  font-size: 0.6em;
}
.brands {
  margin: 0 0.83333em 0 9.16667em;
}
.products {
  float: left;
  font-size: 12px;
  font-size: 1rem;
  text-transform: uppercase;
  width: 102px;
  margin: 0;
}
.products strong {
  color: #333;
  clear: both;
  display: block;
  font-weight: 400;
  width: 200px;
}
.brands {
  float: left;
  font-size: 12px;
  font-size: 1rem;
  text-transform: uppercase;
  width: 106px;
  margin: 0;
}
.brands strong {
  color: #333;
  clear: both;
  display: block;
  font-weight: 400;
  width: 200px;
}
.tiername1 {
  float: right;
  font-size: 12px;
  font-size: 1rem;
  text-transform: uppercase;
  width: 110px;
  margin: 0;
}
.tiername1 strong {
  color: #333;
  clear: both;
  display: block;
  font-weight: 400;
}
.tiername {
  float: right;
  font-size: 12px;
  font-size: 1rem;
  text-transform: uppercase;
  width: 110px;
  margin: 0;
}
.tiername strong {
  color: #333;
  clear: both;
  display: block;
  font-weight: 400;
  width: 200px;
}
.address .data-label {
  float: left;
  font-size: 12px;
  font-size: 1rem;
  text-transform: uppercase;
}
.address span {
  color: #333;
  float: right;
  margin: 0 0.83333em;
  width: 200px;
}
.current-rating {
  margin-top: 0 !important;
  width: 100%;
}
.current-rating .date {
  margin-left: 12%;
}
.compliance-heading,
.current-rating {
  font-size: 12px;
  font-size: 1rem;
  margin: 1.25em 0 0.41667em;
  text-transform: uppercase;
}
.active-scope.filter-scope {
  background: #222;
  background: rgba(255, 255, 255, 0.98);
  box-shadow: 0 2px 5px #222;
  border-radius: 5px;
  left: -360px;
  top: -5px;
  padding-top: 1.25em;
  position: absolute;
}
.filter-scope:before {
  content: '';
  display: block;
  height: 38px;
  top: 140px;
  width: 25px;
  position: absolute;
  left: 350px;
  z-index: 100;
}
.filter-scope ol li:after {
  content: '';
  background: 0 0;
}
.filter-scope li span {
  font-size: 1em;
  text-transform: uppercase;
}
.filter-scope li:after {
  content: '';
}
.filter-scope h3 {
  color: #666;
  display: block;
  float: left;
  font-family: Futura;
  font-size: 22px;
  font-size: 1.83333rem;
  font-weight: 400;
  margin: 0;
  padding: 0 0 10px;
  width: auto;
  text-transform: uppercase;
}
.filter-scope .nav li {
  background: 0 0 !important;
  background-image: none !important;
  white-space: nowrap;
  overflow: hidden;
}
.filter-scope .notice {
  float: right;
  text-align: left;
  width: auto;
  display: block;
}
.filter-scope .notice span {
  display: block;
}
.filter-scope .jspTrack {
  left: -4px;
}
.filter-nav li:first-child {
  border-left: none;
}
.jspPane .filter-nav a,
.jspPane .filter-nav li {
  width: 105px;
}
.apply-filters {
  background-color: #f78e1e;
  background-image: -webkit-linear-gradient(top, #f78e1e, #f38209);
  background-image: linear-gradient(to bottom, #f78e1e, #f38209);
  border-radius: 2px;
  clear: both;
  color: #fff;
  display: inline-block;
  margin: 0 0 0.83333em;
  padding: 0.83333em 2.08333em;
  text-decoration: none;
  text-transform: uppercase;
  text-indent: 0;
  width: auto;
}
.apply-filters:hover {
  color: #ccc;
  background: #f78e1e;
  text-shadow: 0 0 1px #333;
}
.apply-filters.disabled {
  background: #eee;
  color: #666;
  cursor: default;
}
#region-form .apply-filters {
  color: #fff;
}
#region-form .apply-filters.disabled {
  color: #666;
}
.cancel-filters {
  display: inline-block;
  margin: 0 0 0 10px;
  text-transform: uppercase;
}
.cancel-filters a {
  color: #f78e1e;
  font-size: 1.083em;
  margin: 0 0 0 5px;
  text-decoration: none;
}
.faceted-selection {
  overflow: hidden;
  padding: 0 0 0 0.83333em;
}
.faceted-selection h3,
.faceted-selection > div.empty-list {
  display: none;
}
.faceted-selection.on {
  padding-bottom: 0.83333em;
}
.faceted-selection span {
  color: #000;
  display: inline-block;
  float: left;
  height: 20px;
  line-height: 1.66667em;
  margin: 0 0.41667em 0 0;
}
.faceted-selection ul {
  float: left;
  display: inline-block;
  margin: 0;
}
.faceted-selection li {
  color: #999;
  display: inline-block;
  float: left;
  font-size: 12px;
  font-size: 1rem;
  height: 20px;
  line-height: 1.66667em;
  margin: 0 0.41667em 0 0;
  text-transform: uppercase;
}
.faceted-selection > div {
  clear: both;
  float: left;
  text-transform: uppercase;
  width: 100%;
}
#region-form fieldset.pane {
  margin: 0 0 0.83333em;
}
#region-form ul.filter-nav,
#region-form ul.sort-nav {
  border-top: 5px #999 solid;
  display: block;
  clear: both;
  list-style: none;
  margin: 0 0 0.83333em;
  overflow: hidden;
  padding: 0.83333em 0 0;
  width: 100%;
}
.filter-nav li,
.sort-nav li {
  background-color: #ddd;
  background-image: -webkit-linear-gradient(top, #ddd, #ccc);
  background-image: linear-gradient(to bottom, #ddd, #ccc);
  border-top: none;
  border-left: 1px #000 solid;
  border-right: none;
  border-bottom: none;
  border-radius: 2px;
}
.filter-nav li.current a,
.sort-nav li.current a {
  background: #eee;
  color: #909498;
}
.filter-nav a,
.sort-nav a {
  color: #666;
  font-size: 12px;
  font-size: 1rem;
  padding: 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}
.filter-nav a,
.filter-nav li,
.sort-nav a,
.sort-nav li {
  border: none !important;
  float: left;
  font-size: 12px;
  font-size: 1rem;
  height: 30px;
  line-height: 2.5em;
  padding: 0;
  margin: 0 0.08333em 0 0;
  width: 105px;
}
.filter-nav li:after,
.filter-nav ol li:after,
.sort-nav li:after,
.sort-nav ol li:after {
  content: '';
}
.legend-key {
  background: #fff;
  bottom: 0;
  height: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 1000;
}
.legend-key .left {
  float: left;
}
.legend-key .right {
  float: right;
}
.legend-key .left,
.legend-key .right {
  width: 50%;
}
.wrapper {
  margin: 4.16667em auto 0;
  max-width: 980px;
  width: 100%;
}
.primary {
  color: #999;
  float: right;
  margin: 0 1.25em 0 0;
  width: 60%;
  font-size: 14px;
  font-size: 1.16667rem;
}
.primary h2 {
  color: #666;
  text-transform: uppercase;
}
.primary h3 {
  color: #666;
  font-weight: 700;
}
.primary ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.primary li {
  color: #999;
  font-size: 0.8em;
  padding: 0 0 0.83333em 1.66667em;
}
.primary .section {
  font-size: 18px;
  font-size: 1.5rem;
  padding-top: 0.83333em;
  width: 90%;
}
.secondary {
  float: left;
  padding-top: 80px;
  padding-left: 0.83333em;
  width: 35%;
}
.secondary ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.secondary ul li {
  color: #999;
  font-size: 0.9em;
  font-weight: 400;
  padding: 0 0 0 8.33333em;
  text-transform: uppercase;
}

.factory-key,
.manufacturer-key {
  height: 50px;
  line-height: 3.75em;
  margin-bottom: 1.25em;
}
.footer {
  clear: both;
  display: inline-block;
  margin: 1.66667em 0 0;
  width: 100%;
}
.footer h3 {
  color: #999;
  font-size: 12px;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0;
  padding-left: 0.83333em;
  text-transform: uppercase;
}
.footer-brands {
  float: left;
  margin: 0 5%;
}
.region-scope .countries,
.region-scope .factories,
.region-scope .workers {
  margin: 0 0.83333em;
}
.scope-numbers {
  clear: both;
  margin-left: 0.41667em !important;
  overflow: hidden;
}
.scope-numbers sup {
  font-size: 0.6em;
}
.total-sales {
  height: 150px;
  position: relative;
  float: left;
  width: 150px;
}
.dollar,
.percent {
  position: absolute;
}
.dollar {
  top: 0;
  right: 0;
}
.percent {
  color: #fff;
  font-family: Futura;
  font-size: 3em;
  font-weight: 400;
  left: 0;
  top: 15px;
}
.percent span {
  display: block;
  font-size: 14px;
  font-size: 1.16667rem;
  text-transform: uppercase;
}
.worker-age,
.worker-type {
  border-top: 1px #999 solid;
  float: left;
  font-size: 12px;
  font-size: 1rem;
  margin-top: 0.83333em;
  margin-right: 0.83333em;
  padding-top: 0.83333em;
  text-align: center;
  text-transform: uppercase;
  width: 95px;
}
.worker-age strong,
.worker-type strong {
  clear: both;
  color: #fff;
  display: block;
  font-family: Futura;
  font-size: 3em;
  font-weight: 400;
}
.scope-details .countries,
.scope-details .factories,
.scope-details .workers {
  font-size: 10px;
  font-size: 0.83333rem;
  text-transform: uppercase;
}
.world-scope .countries,
.world-scope .factories,
.world-scope .workers {
  margin: 0 1em;
}
body,
html {
  overflow: auto;
}
body {
  overflow: auto;
}
.explore-data-btn {
  display: inline-block;
  margin: 25px 0 5px;
  overflow: hidden;
}
.highcharts-container {
  overflow: visible !important;
}
.start-view {
  height: 846px;
  left: -10px;
  position: relative;
  width: 678px;
  z-index: 10;
}
.start-view a {
  position: absolute;
  height: 200px;
  text-indent: -99999em;
  z-index: 100;
}
.start-view a.explore-button {
  left: 15px;
  top: 90px;
  width: 380px;
}
.start-view a.timeline-view {
  right: 22px;
  top: 259px;
  width: 255px;
}
.historical-view {
  float: left;
  height: 1200px;
  margin: 0 0 2.08333em -1.25em;
  position: relative;
  width: 683px;
  z-index: 1000;
}

.historical-view h1 {
  color: #ccc;
  margin: 1.33333em;
  position: relative;
  z-index: 100;
}
#historical-slider {
  background: 0 0 !important;
  border: none !important;
  cursor: pointer;
  height: 30px !important;
  margin: 34.16667em auto 0;
  width: 125px;
}
.ui-slider-horizontal {
  background: 0 0 !important;
  border: none !important;
  cursor: pointer;
  height: 30px !important;
  margin: 34.16667em auto 0;
  width: 125px;
}
.ui-slider-handle {
  border: none !important;
  cursor: pointer !important;
  height: 20px !important;
  margin-top: 0.83333em;
  margin-left: -2.91667em !important;
  outline: 0 !important;
  width: 60px !important;
}
.jspContainer {
  overflow: hidden;
  position: relative;
}
.jspPane {
  position: absolute;
}
.jspVerticalBar {
  position: absolute;
  top: 0;
  right: 0;
  width: 9px;
  height: 100%;
}
.jspVerticalBar * {
  margin: 0;
  padding: 0;
}
.jspVerticalBar .jspArrow {
  height: 16px;
}
.jspVerticalBar .jspArrow:focus {
  outline: 0;
}
.jspHorizontalBar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 16px;
}
.jspHorizontalBar * {
  margin: 0;
  padding: 0;
}
.jspHorizontalBar .jspCap {
  float: left;
}
.jspHorizontalBar .jspTrack {
  float: left;
  height: 100%;
}
.jspHorizontalBar .jspDrag {
  float: left;
  height: 100%;
}
.jspHorizontalBar .jspArrow {
  width: 16px;
  float: left;
  height: 100%;
}
.jspCap {
  display: none;
}
.jspTrack {
  background: #666;
  border-radius: 5px;
  position: relative;
}
.jspDrag {
  background: #ccc;
  border-radius: 5px;
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer;
}
.jspArrow {
  background: #50506d;
  text-indent: -20000px;
  display: block;
  cursor: pointer;
}
.jspArrow.jspDisabled {
  cursor: default;
  background: #80808d;
}
.jspCorner {
  background: #eee ef4;
  float: left;
  height: 100%;
}
.country-scope .jspVerticalBar {
  position: absolute;
  top: 10px;
  right: 7px;
  width: 9px;
  height: 95%;
}
* html .jspCorner {
  margin: 0 -3px 0 0;
}

div.divider {
  border-bottom: 1px solid #808080;
  padding-top: 10px;
  padding-bottom: 10px;
}
.factories1 {
  font-size: 1em;
  text-transform: uppercase;
}
.countries1 {
  font-size: 1em;
  text-transform: uppercase;
}
div.tier2 {
  text-align: left;
}
#region-form-wrapper {
  width: 375px;
}
#region-form {
  width: 375px;
}
.facets > li.current-scope fieldset {
  width: 358px;
}

.hidden {
  display: none;
}
